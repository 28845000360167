import React, { useEffect, useState } from "react";
import Table from "./Table/index";
import { useDispatch, useSelector } from "react-redux";
import { getBackgroundProcesses } from "../../redux/slices/video.slice";

export default function Music() {
  const dispatch = useDispatch();
  const [numberOfProcess, setNumberOfProcess] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(
        getBackgroundProcesses({
          payload: "video",
          callback: ({data}) => {
            setNumberOfProcess(data?.number);
            console.log(data);
          },
        })
      );
    }, 5000); // 20 seconds interval

    // Cleanup the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);
  return (
    <div>
      <div
        style={{
          marginBottom: 10,
        }}
      >
        <p>Number of Processes Running: {numberOfProcess} </p>
      </div>
      <Table />
    </div>
  );
}
