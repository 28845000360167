import React, { useEffect, useState } from "react";
import styles from "./Table.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSubscription,
  getSubscription,
} from "../../../redux/slices/subscription.slice";
import Loader from "../../../components/Loader";
import * as XLSX from "xlsx"; // Import xlsx library
import ReactPaginate from "react-paginate";
import toast from "react-hot-toast";
import Icon from "../../../components/Icon";

const Table = () => {
  const dispatch = useDispatch();
  //pagination
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const { subscriptions, loading, page, pageSize, totalPages } = useSelector(
    (state) => state.subscription
  );

  //

  const getSubscriptionData = (currentPage) => {
    dispatch(
      getSubscription({
        payload: { page: currentPage },
        callback: (data) => {
          if (data?.status === 200) {
            // handle successful data fetching if needed
          } else {
          }
        },
      })
    );
  };

  const deleteSubs = (id) => {
    dispatch(
      deleteSubscription({
        payload: id,
        callback: (data) => {
          if (data?.status === 200) {
            toast.success("Subscription Deleted Successfully.");
            getSubscriptionData();
            // handle successful data fetching if needed
          } else {
            toast.error("Error Deleting Subscription");
          }
        },
      })
    );
  };

  useEffect(() => {
    getSubscriptionData(currentPage);
  }, [dispatch, currentPage]);
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1); // Update the current page
  };
  const exportToCSV = () => {
    if (!Array.isArray(subscriptions?.subscriptions)) {
      console.error("subscriptions is not an array");
      return;
    }

    try {
      // Convert subscription data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(subscriptions?.subscriptions);

      // Convert the worksheet to CSV
      const csvData = XLSX.utils.sheet_to_csv(worksheet);

      // Create a Blob object with the CSV data
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });

      // Create a download link for the CSV file
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "subscriptions_data.csv"; // Specify the file name

      // Append the link to the body and trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          marginBottom: 10,
        }}
      >
        <button
          onClick={exportToCSV}
          style={{
            backgroundColor: "#FF3C00",
            padding: 10,
            color: "white",
            borderRadius: 6,
            outline: "none",
          }}
        >
          Export
        </button>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.table}>
          <div className={styles.rowHeading}>
            <div className={styles.col} data-label="Title">
              Email/Phone Number
            </div>
            <div className={styles.col} data-label="Created At">
              Created At
            </div>
            <div className={styles.col} data-label="Created At">
              Actions
            </div>
          </div>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
              }}
            >
              <Loader />
            </div>
          ) : subscriptions?.subscriptions?.length > 0 ? (
            subscriptions?.subscriptions?.map((x, index) => (
              <div
                className={styles.row}
                style={{
                  backgroundColor: "#f4f4f4",
                }}
                key={index}
              >
                <div className={styles.col} data-label="Title">
                  <p>
                    {x?.email && x?.phoneNumber
                      ? x?.email + ` / ${x?.phoneNumber}`
                      : x?.email
                      ? x?.email
                      : x?.phoneNumber}
                  </p>
                </div>
                <div className={styles.col} data-label="Title">
                  <p>{x?.createdAt}</p>
                </div>
                <div className={styles.col} onClick={() => deleteSubs(x?.id)}>
                  <Icon
                    name={"trash"}
                    size="24"
                    className={styles.iconColorRed}
                  />
                </div>
              </div>
            ))
          ) : (
            <p
              style={{
                fontSize: 20,
                textAlign: "center",
                marginTop: "100px",
              }}
            >
              No Data Found
            </p>
          )}
        </div>
      </div>
      <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        breakLabel={"..."}
        pageCount={totalPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={handlePageChange}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        activeClassName={"active"} // Highlight active tab
      />
    </div>
  );
};

export default Table;
