import React, { useState } from "react";
import Loader from "../../components/Loader";
import { useDispatch } from "react-redux";
import { sendPhoneNumberMessage } from "../../redux/slices/subscription.slice";
import toast from "react-hot-toast";

export default function SendSms() {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const sendMessage = () => {
    try {
      if (!message) {
        toast.error("Message cannot be empty");
        return false;
      } else {
        setLoading(true);
        dispatch(
          sendPhoneNumberMessage({
            payload: {
              message,
            },
            callback: (data) => {
              console.log(data);
              if (data?.status == 200) {
                toast.success("Message sent successfully");
                setMessage("");
                setLoading(false);
              } else {
                toast.success("Failed to send Message");
                setLoading(false);
              }
            },
          })
        );
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "white",
        height: "100%",
        padding: "20px",
      }}
    >
      <p>Message</p>
      <textarea
        cols={10}
        placeholder="Enter Message"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        style={{
          width: "100%",
          resize: "none",
          marginTop: 10,
          height: 200,
          border: "none",
          backgroundColor: "#f4f4f4",
          borderRadius: 10,
          padding: 20,
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: 10,
        }}
      >
        <button
          onClick={sendMessage}
          disabled={loading}
          style={{
            backgroundColor: "#ff3c00",
            color: "#fff",
            border: "none",
            borderRadius: 4,
            padding: 10,
            height: 40,
            // alignSelf: "flex-start",
          }}
        >
          {loading ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Loader height={25} width={25} />
            </div>
          ) : (
            "Send Message"
          )}
        </button>
      </div>
    </div>
  );
}
