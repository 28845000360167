import React, { useEffect, useState } from "react";
import styles from "./Table.module.scss";
import Item from "./Item";
import Tooltip from "../../../components/Tooltip";
import Modal from "../../../components/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteVideo,
  getFeaturedVideos,
  updateVideo,
} from "../../../redux/slices/video.slice";
import Icon from "../../../components/Icon";
import moment from "moment";
import toast from "react-hot-toast";
import Loader from "../../../components/Loader";
import cn from "classnames";
import Form from "./form/Form";
import {
  addBlogImage,
  addFeaturedVideo,
  uploadVideo,
  videoThumbnail,
} from "../../../redux/slices/blog.slice";
import ReactPaginate from "react-paginate";

const Table = () => {
  const [visible, setVisible] = useState(false);

  const dispatch = useDispatch();
  //pagination
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const {
    featuredVideos: videos,
    loading,
    page,
    pageSize,
    totalPages,
  } = useSelector((state) => state.video);
  //

  const getVideosData = (currentPage) => {
    dispatch(
      getFeaturedVideos({
        payload: { page: currentPage },
        callback: (data) => {
          if (data?.status === 200) {
            // handle successful data fetching if needed
          } else {
          }
        },
      })
    );
  };
  //pagination
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1); // Update the current page
  };
  //
  useEffect(() => {
    getVideosData(currentPage);
  }, [dispatch, currentPage]);

  //form
  const [state, setState] = useState({
    title: "",
    reelUrl: "",
    waterMarkVideoUrl: "",
    thumbnailUrl: "",
    buttonLink: "",
    buttonTitle: "",
    imageUrl: "",
    image: "",
    isShowFeatured: false,
  });
  const [edit, setEdit] = useState(null);
  const [videoLoading, setVideoLoading] = useState(false);
  const [featuredVideoLoading, setFeaturedVideoLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const stateChangeHandler = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleImageFileChange = (e) => {
    const file = e.target.files[0];
    stateChangeHandler("imageUrl", file);
    // if (!file) {
    //   toast.error("No file selected");
    //   return false;
    // }
    // try {
    //   setImageLoading(true);
    //   const formData = new FormData();
    //   formData.append("file", file);
    //   dispatch(
    //     addBlogImage({
    //       payload: formData,
    //       callback: (data) => {
    //         if (data?.status === 200) {
    //           stateChangeHandler("image", file);
    //           stateChangeHandler("imageUrl", data?.imageUrl);
    //           setTimeout(() => {
    //             stateChangeHandler("thumbnailUrl", data?.imageUrl);
    //           }, 1000);
    //           setImageLoading(false);
    //         } else {
    //           toast.error("Something went wrong");
    //         }
    //       },
    //     })
    //   );
    // } catch (error) {
    //   setImageLoading(false);
    //   toast.error("Something went wrong");
    // }
  };
  const handleReelFileChange = (e) => {
    const file = e.target.files[0];
    stateChangeHandler("reelUrl", file);
    // if (!file) {
    //   toast.error("No file selected");
    //   return false;
    // }
    // try {
    //   setVideoLoading(true);
    //   const formData = new FormData();
    //   formData.append("file", file);
    //   formData.append("type", "video");
    //   dispatch(
    //     uploadVideo({
    //       payload: formData,
    //       callback: (data) => {
    //         if (data?.status === 200) {
    //           dispatch(
    //             videoThumbnail({
    //               payload: formData,
    //               callback: (datainner) => {
    //                 if (datainner?.status === 200) {
    //                   setState({
    //                     ...state,
    //                     reelUrl: data?.videoUrl,
    //                     waterMarkVideoUrl: data?.watermarkVideoUrl,
    //                     thumbnailUrl: datainner?.thumbnailUrl,
    //                   });
    //                   setVideoLoading(false);
    //                 } else {
    //                   toast.error(
    //                     "Something went wrong while fetching thumbnail"
    //                   );
    //                   setVideoLoading(false);
    //                 }
    //               },
    //             })
    //           );
    //         } else {
    //           toast.error(data?.message);
    //           setVideoLoading(false);
    //         }
    //       },
    //     })
    //   );
    // } catch (error) {
    //   console.error("Error handling file change:", error);
    //   toast.error("An error occurred while handling the file change");
    //   setVideoLoading(false);
    // }
  };

  const uploadFeaturedVideo = () => {
    // Simple validation checks
    const validateForm = () => {
      if (!state?.title) {
        toast.error("Title is required");
        return false;
      }
      if (!state?.reelUrl && !state?.imageUrl) {
        toast.error("Either Reel Video or Image is required");
        return false;
      }
      if (state?.buttonTitle) {
        if (state?.buttonTitle?.length > 25) {
          toast.error("Button Title must be less than 20 characters");
          return false;
        }
        if (!state?.buttonLink) {
          toast.error("Button Link is required when Button Title is provided");
          return false;
        }
      }
      return true;
    };

    try {
      if (!validateForm()) return; // Exit if validation fails
      setFeaturedVideoLoading(true);
      const formData = new FormData();
      formData.append("title", state.title);
      formData.append("isShowFeatured", state.isShowFeatured);
      if (state.imageUrl) {
        formData.append("thumbnailUrl", state.imageUrl);
      }
      if (state.reelUrl) {
        formData.append("videoUrl", state.reelUrl);
      }
      if (state?.imageUrl) {
        formData.append("imageUrl", state.imageUrl);
      }
      if (state?.buttonLink) {
        formData.append("buttonLink", state.buttonLink);
      }
      if (state?.buttonTitle) {
        formData.append("buttonTitle", state.buttonTitle);
      }

      dispatch(
        addFeaturedVideo({
          payload: formData,
          callback: (data) => {
            setFeaturedVideoLoading(false);
            if (data?.status === 200) {
              setState({});
              setVisible(false);
              toast.success(data?.message);
            } else {
              toast.error(data?.message);
            }
          },
        })
      );
    } catch (error) {
      setFeaturedVideoLoading(false);
      toast.error("Something went wrong");
    }
  };

  //form
  //edit

  const ctaEditHandler = (data) => {
    setEdit(data);
    stateChangeHandler("title", data?.title);
    setVisible(true);
  };
  const updateVideoTitle = () => {
    try {
      setFeaturedVideoLoading(true);
      if (!state.title) {
        toast.error("Title is required");
        return;
      } else {
        dispatch(
          updateVideo({
            payload: {
              videoId: edit?.id,
              title: state.title,
            },
            callback: (data) => {
              if (data?.status === 200) {
                getVideosData();
                toast.success("Updated Successfully");
                setFeaturedVideoLoading(false);
                setVisible(false);
              } else {
                setFeaturedVideoLoading(false);
                toast.error("Something went wrong");
              }
            },
          })
        );
      }
    } catch (error) {
      setFeaturedVideoLoading(false);
      toast.error("Something went wrong");
    }
  };
  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleDeleteBlog = (id) => {
    try {
      setDeleteLoading(true);
      dispatch(
        deleteVideo({
          payload: {
            id,
          },
          callback: (data) => {
            if (data.status == 200) {
              toast.success(data?.message);
              getVideosData();
              setDeleteLoading(false);
            } else {
              setDeleteLoading(false);
              toast.error(data?.message);
            }
          },
        })
      );
    } catch (error) {
      setDeleteLoading(true);
      toast.error(error?.message);
    }
  };
  return (
    <div>
      <Modal
        visible={visible}
        onClose={() => {
          setEdit(null);
          setVisible(false);
        }}
        title="Add Sponsored Video"
      >
        <Form
          state={state}
          stateChangeHandler={stateChangeHandler}
          videoLoading={videoLoading}
          imageLoading={imageLoading}
          featuredVideoLoading={featuredVideoLoading}
          handleImageFileChange={handleImageFileChange}
          handleReelFileChange={handleReelFileChange}
          uploadFeaturedVideo={uploadFeaturedVideo}
          edit={edit}
          updateVideoTitle={updateVideoTitle}
        />
      </Modal>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          marginBottom: 10,
        }}
      >
        <button
          className={cn("button-square-stroke button-small", styles.head)}
          onClick={() => setVisible(true)}
        >
          <Icon name="plus" size="18" />
        </button>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.table}>
          <div
            className={styles.rowHeading}
            style={{
              width: "100%",
            }}
          >
            <div className={styles.col} data-label="Title">
              Title
            </div>
            <div className={styles.col} data-label="Video">
              Video
            </div>
            <div className={styles.col} data-label="Thumbnail">
              Thumbnail
            </div>

            <div className={styles.col} data-label="Featured">
              Sponsored
            </div>
            <div className={styles.col} data-label="Created At">
              Created At
            </div>
            <div className={styles.col} data-label="Action">
              Action
            </div>
          </div>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
              }}
            >
              <Loader />
            </div>
          ) : videos?.length > 0 ? (
            videos?.map((x, index) => (
              <div
                className={styles.row}
                style={{
                  backgroundColor: "#f4f4f4",
                }}
                key={index}
              >
                <div className={styles.col} data-label="Title">
                  <p
                    style={{
                      wordWrap: "break-word",
                    }}
                  >
                    {x?.title?.length > 100
                      ? x?.title?.slice(0, 100) + "..."
                      : x?.title}
                  </p>
                </div>
                <div className={styles.col} data-label="Video">
                  <a
                    href={x?.videoUrl || x?.reelImageUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#6F767E",
                      borderBottom: "1px solid #6F767E",
                    }}
                  >
                    Video_URL
                  </a>
                </div>
                <div className={styles.col} data-label="Thumbnail">
                  <a
                    href={x?.thumbnailUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#6F767E",
                      borderBottom: "1px solid #6F767E",
                    }}
                  >
                    <img src={x?.thumbnailUrl} height={140} width={160} />
                  </a>
                </div>

                <div className={styles.colBadge} data-label="Verified">
                  <span
                    style={{
                      backgroundColor: x?.featured ? "#3eb489" : "#FF7F7F",
                      paddingTop: 5,
                      paddingBottom: 5,
                      paddingLeft: 10,
                      paddingRight: 10,
                      borderRadius: 24,
                    }}
                  >
                    {x?.featured ? "Yes" : "No"}
                  </span>
                </div>
                <div className={styles.col} data-label="Created At">
                  {moment(x?.createdAt).format("MM/DD/YYYY")}
                </div>
                <div className={styles.col} data-label="Action">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    {deleteLoading ? (
                      <Loader height={20} width={20} />
                    ) : (
                      <div onClick={() => handleDeleteBlog(x?.id)}>
                        <Icon
                          name={"trash"}
                          size="24"
                          className={styles.iconColorRed}
                        />
                      </div>
                    )}
                    <div onClick={() => ctaEditHandler(x)}>
                      <Icon
                        name={"edit"}
                        size="24"
                        className={styles.iconColor}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p
              style={{
                fontSize: 20,
                textAlign: "center",
                marginTop: "100px",
              }}
            >
              No Data Found
            </p>
          )}
        </div>
      </div>
      <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        breakLabel={"..."}
        pageCount={totalPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={handlePageChange}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        activeClassName={"active"} // Highlight active tab
      />
    </div>
  );
};

export default Table;
