import React, { useEffect, useState } from "react";
import styles from "./Table.module.scss";
import Tooltip from "../../../components/Tooltip";
import Modal from "../../../components/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  addVideo,
  getVideos,
  verifyVideo,
} from "../../../redux/slices/video.slice";
import Icon from "../../../components/Icon";
import moment from "moment";
import TextInput from "../../../components/TextInput";
import Filters from "../../../components/Filters";
import Dropdown from "../../../components/Dropdown";
import Checkbox from "../../../components/Checkbox";
import toast from "react-hot-toast";
import Form from "./form/Form";
import {
  addBlog,
  getBlogs,
  uploadVideo,
  videoThumbnail,
} from "../../../redux/slices/blog.slice";
import Loader from "../../../components/Loader";
import cn from "classnames";
import {
  addTag,
  deleteAudio,
  deleteTag,
  getTags,
  searchTags,
  updateTag,
  uploadAudioMusic,
} from "../../../redux/slices/tags.slice";
import SearchForm from "../../../components/Form";
import {
  addInfluencer,
  deleteInfluencer,
  getInfluencers,
  updateInfluencer,
  updateInfluencerStatus,
} from "../../../redux/slices/influencity.slice";
import ReactPaginate from "react-paginate";

const Table = () => {
  const dispatch = useDispatch();
  //pagination
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const { influencers, loading, page, pageSize, totalPages } = useSelector(
    (state) => state.influencer
  );

  //
  const [tagsLoading, setTagsLoading] = useState(false);
  const [tagsList, setTagsList] = useState([]);
  const [addVideoModal, setAddVideoModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [tagName, setTagName] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState(null);
  const getInfluencityData = (currentPage) => {
    dispatch(
      getInfluencers({
        payload: { page: currentPage }, // Pass the current page to the API
        callback: (data) => {
          if (data?.status !== 200) {
            toast.error("Failed to fetch influencers");
          }
        },
      })
    );
  };

  //pagination
  useEffect(() => {
    getInfluencityData(currentPage); // Fetch data based on the current page
  }, [currentPage]); // Only run when currentPage changes

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1); // Update the current page
  };
  //
  useEffect(() => {
    getInfluencityData();
  }, [dispatch]);
  const uploadProfile = (e) => {
    setFile(e.target.files[0]);
  };
  const addInfluencity = () => {
    setTagsLoading(true);
    try {
      if (!name) {
        toast.error("Name is required");
        return false;
      }
      if (!description) {
        toast.error("Description is required");
        return false;
      }
      if (!file) {
        toast.error("File is required");
        return false;
      } else {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("description", description);
        formData.append("file", file);

        dispatch(
          addInfluencer({
            payload: formData,
            callback: (data) => {
              if (data?.status === 200) {
                setName("");
                setDescription("");
                getInfluencityData();
                setEditData(null);
                setFile(null);
                setTagsLoading(false);
                toast.success(data.message);
                setAddVideoModal(false);
              } else {
                setTagsLoading(false);
                toast.error(data.message);
              }
            },
          })
        );
      }
    } catch (error) {
      setTagsLoading(false);
      toast.error("Failed to add tag");
    }
  };

  const ctaDeleteHandler = (x, status) => {
    try {
      dispatch(
        deleteInfluencer({
          payload: {
            id: x?.id,
          },
          callback: (data) => {
            if (data?.status === 200) {
              getInfluencityData();
              toast.success(data?.message);
            } else {
              toast.error(data?.message);
            }
          },
        })
      );
    } catch (error) {
      toast.error("Failed to Updated Influencer");
    }
  };

  const ctaEditHandler = (x) => {
    console.log(x);
    setEditData(x);
    setName(x?.name);
    setDescription(x?.description);
    setAddVideoModal(true);
  };
  console.log("description", description);
  const updateInfluencerData = () => {
    try {
      if (!name) {
        toast.error("Name is required");
        return false;
      }
      if (!description) {
        toast.error("Description is required");
        return false;
      }
      const formData = new FormData();
      formData.append("name", name);
      formData.append("description", description);
      formData.append("id", editData?.id);
      if (file) {
        formData.append("file", file);
      }
      setTagsLoading(true);
      dispatch(
        updateInfluencer({
          payload: formData,
          callback: (data) => {
            if (data?.status === 200) {
              getInfluencityData();
              setTagsLoading(false);
              setEditData({});
              setName("");
              setDescription("");
              setAddVideoModal(false);
              setFile(null);
              setEditData(null);
              toast.success("Influencer updated successfully");
            } else {
              setTagsLoading(false);
              toast.error("Failed to update Influencer");
            }
          },
        })
      );
    } catch (error) {
      setTagsLoading(false);
      toast.error("Failed to update Influencer");
    }
  };
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState(query);
  useEffect(() => {
    const handler = setTimeout(() => {
      if (query === "") {
        dispatch(
          getTags({
            payload: "true",
          })
        );
      } else {
        setDebouncedQuery(query);
      }
    }, 300); // Adjust debounce delay as needed

    return () => {
      clearTimeout(handler);
    };
  }, [query]);

  useEffect(() => {
    if (debouncedQuery.trim() === "") return;
    const fetchData = () => {
      dispatch(
        searchTags({
          payload: encodeURIComponent(debouncedQuery),
        })
      );
    };

    fetchData();
  }, [debouncedQuery]);
  return (
    <div>
      <Modal
        visible={addVideoModal}
        onClose={() => {
          setEditData(null);
          setName("");
          setDescription("");
          setAddVideoModal(false);
        }}
        title={editData ? "Edit Influencer" : "Add Influencer"}
      >
        <div
          style={{
            margintop: 100,
          }}
        >
          <Form
            editData={editData}
            loading={tagsLoading}
            onClick={addInfluencity}
            name={name}
            description={description}
            setName={setName}
            setDescription={setDescription}
            updateInfluencerData={updateInfluencerData}
            uploadProfile={uploadProfile}
            file={file}
          />
        </div>
      </Modal>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingBottom: 20,
          alignItems: "center",
          gap: 10,
        }}
      >
        {/* <SearchForm
          value={query}
          setValue={setQuery}
          style={{
            backgroundColor: "white",
            height: 50,
            borderRadius: 50,
          }}
          placeholder="Search Tag"
          type="text"
          name="search"
          icon="search"
        /> */}
        <button
          className={cn("button-square-stroke button-small", styles.head)}
          onClick={() => setAddVideoModal(true)}
        >
          <Icon name="plus" size="18" />
        </button>
      </div>

      <div className={styles.wrapper}>
        <div className={styles.table}>
          <div className={styles.rowHeading}>
            <div className={styles.col} data-label="Title">
              Profile
            </div>
            <div className={styles.col} data-label="Title">
              Name
            </div>
            <div className={styles.col} data-label="Title">
              Description
            </div>
            <div className={styles.col} data-label="Video">
              Status
            </div>
            <div className={styles.col} data-label="Thumbnail">
              Created At
            </div>

            <div className={styles.col} data-label="Created At">
              Action
            </div>
          </div>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
              }}
            >
              <Loader />
            </div>
          ) : influencers?.length > 0 ? (
            influencers?.map((x, index) => (
              <div
                className={styles.row}
                style={{
                  backgroundColor: "#f4f4f4",
                }}
                key={index}
              >
                <div className={styles.col} data-label="Title">
                  <img
                    src={x?.profile}
                    style={{
                      height: 50,
                      width: 50,
                      borderRadius: "100%",
                    }}
                  />
                </div>
                <div className={styles.col} data-label="Title">
                  <p>{x?.name}</p>
                </div>
                <div className={styles.col} data-label="Title">
                  <p>{x?.description}</p>
                </div>
                <div className={styles.col} data-label="Status">
                  <p>{x?.status}</p>
                </div>

                <div className={styles.col} data-label="Created At">
                  {moment(x?.createdAt).format("MM/DD/YYYY")}
                </div>
                <div className={styles.col} data-label="Action">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    {x?.status == "ACTIVE" ? (
                      <div onClick={() => ctaDeleteHandler(x, "UNACTIVE")}>
                        <Icon
                          name={"trash"}
                          size="24"
                          className={styles.iconColorRed}
                        />
                      </div>
                    ) : (
                      <div onClick={() => ctaDeleteHandler(x, "ACTIVE")}>
                        <Icon
                          name={"check"}
                          size="24"
                          className={styles.iconColorGreen}
                        />
                      </div>
                    )}
                    <div onClick={() => ctaEditHandler(x)}>
                      <Icon
                        name={"edit"}
                        size="24"
                        className={styles.iconColorGreen}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p
              style={{
                fontSize: 20,
                textAlign: "center",
                marginTop: "100px",
              }}
            >
              No Data Found
            </p>
          )}
        </div>
      </div>
      <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        breakLabel={"..."}
        pageCount={totalPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={handlePageChange}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        activeClassName={"active"} // Highlight active tab
      />
    </div>
  );
};

export default Table;
