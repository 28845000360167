import React, { useEffect, useState } from "react";
import Table from "./Table/index";
import Icon from "../../components/Icon";
import TextInput from "../../components/TextInput";
import SearchForm from "../../components/Form";
import { useDispatch, useSelector } from "react-redux";
import {
  getBlogs,
  searchBlog,
  updateManyBlog,
} from "../../redux/slices/blog.slice";
import {
  getAllVideos,
  getBackgroundProcesses,
  verifyVideo,
} from "../../redux/slices/video.slice";
import Filters from "../../components/Filters";
import styles from "./Table/Table.module.scss";
import cn from "classnames";
import {
  addCategory,
  getCategories,
  updateCategory,
} from "../../redux/slices/category.slice";
import { getJSDocTags } from "typescript";
import { getAllTags, getTags } from "../../redux/slices/tags.slice";
import toast from "react-hot-toast";
export default function AddBlog() {
  const [visible, setVisible] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [edit, setEdit] = useState(null);
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState(query);
  const [filterVisible, setFilterVisible] = useState(false);
  const [videoList, setVideoList] = useState([]);
  const [selectedBlogs, setSelectedBlogs] = useState([]);
  //pagination
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const { loading, blogs, page, pageSize, totalPages } = useSelector(
    (state) => state.blogs
  );
  const dispatch = useDispatch();
  const [numberOfProcess, setNumberOfProcess] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(
        getBackgroundProcesses({
          payload: "blog",
          callback: ({ data }) => {
            setNumberOfProcess(data?.number);
            console.log(data);
          },
        })
      );
    }, 5000); // 20 seconds interval

    // Cleanup the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    const handler = setTimeout(() => {
      if (query === "") {
        dispatch(
          getBlogs({
            payload: {
              page: currentPage,
            },
          })
        );
      } else {
        setDebouncedQuery(query);
      }
    }, 300); // Adjust debounce delay as needed

    return () => {
      clearTimeout(handler);
    };
  }, [query]);

  useEffect(() => {
    if (debouncedQuery.trim() === "") return;
    const fetchData = () => {
      dispatch(
        searchBlog({
          payload: encodeURIComponent(debouncedQuery),
        })
      );
    };
console.log("debouncedQuery",debouncedQuery)
    fetchData();
  }, [debouncedQuery]);
  const ctaFilterHandler = (filter) => {
    dispatch(
      getBlogs({
        payload: { filter, page: currentPage },
        callback: (data) => {
          if (data?.status === 200) {
            // handle successful data fetching if needed
          } else {
          }
        },
      })
    );
    setFilterVisible(false);
  };

  const getBlogsData = (filter, page) => {
    dispatch(
      getBlogs({
        payload: { filter, page },
        callback: (data) => {
          if (data?.status === 200) {
            // handle successful data fetching if needed
          } else {
          }
        },
      })
    );
    dispatch(
      getCategories({
        payload: filter,
        callback: (data) => {
          if (data?.status === 200) {
            // handle successful data fetching if needed
          } else {
          }
        },
      })
    );

    dispatch(
      getAllVideos({
        payload: "",
        callback: (data) => {
          if (data?.status === 200) {
            const videoList = data?.data?.videos?.map((item) => {
              return {
                id: item?.id,
                title: item?.title,
              };
            });
            setVideoList(videoList);
            // handle successful data fetching if needed
          } else {
            setVideoList([]);
          }
        },
      })
    );
    dispatch(
      getAllTags({
        payload: {},
        callback: (data) => {},
      })
    );
  };
  useEffect(() => {
    getBlogsData();
  }, []);
  //pagination
  useEffect(() => {
    getBlogsData("", currentPage); // Fetch data based on the current page
  }, [currentPage]); // Only run when currentPage changes

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1); // Update the current page
  };
  //
  const deleteBlogs = () => {
    dispatch(
      updateManyBlog({
        payload: {
          ids: selectedBlogs,
        },
        callback: (data) => {
          if (data.status === 200) {
            toast.success("Deleted Successfully");
            dispatch(
              getBlogs({
                payload: "",
                callback: (data) => {
                  if (data?.status === 200) {
                    // handle successful data fetching if needed
                  } else {
                  }
                },
              })
            );
          }
        },
      })
    );
  };
  return (
    <div>
      <div
        style={{
          marginBottom: 10,
        }}
      >
        <p>Number of Processes Running: {numberOfProcess} </p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginBottom: 20,
          marginRight: 5,
          gap: 10,
        }}
      >
        <SearchForm
          value={query}
          setValue={setQuery}
          style={{
            backgroundColor: "white",
            height: 50,
            borderRadius: 50,
          }}
          placeholder="Search Blog"
          type="text"
          name="search"
          icon="search"
        />
        <Filters
          title={"Status Filter"}
          visible={filterVisible}
          setVisible={setFilterVisible}
        >
          <div
            className={styles.filterOptions}
            onClick={() => ctaFilterHandler("")}
          >
            {/* <Icon name="check" size="20" /> */}
            <p>All</p>
          </div>
          <div
            className={styles.filterOptions}
            onClick={() => ctaFilterHandler("ACTIVE")}
          >
            {/* <Icon name="check" size="20" /> */}
            <p>Active</p>
          </div>
          <div
            className={styles.filterOptions}
            onClick={() => ctaFilterHandler("DRAFT")}
          >
            {/* <Icon name="clock" size="20" /> */}
            <p>Draft</p>
          </div>
          <div
            className={styles.filterOptions}
            onClick={() => ctaFilterHandler("UNLISTED")}
          >
            {/* <Icon name="clock" size="20" /> */}
            <p>Unlist</p>
          </div>
          <div
            className={styles.filterOptions}
            onClick={() => ctaFilterHandler("DELETED")}
          >
            {/* <Icon name="clock" size="20" /> */}
            <p>Deleted</p>
          </div>
        </Filters>

        <button
          className={cn("button-square-stroke button-small", styles.head)}
          onClick={() => {
            setEdit(null);
            setVisible(true);
          }}
        >
          <Icon name="plus" size="18" />
        </button>
        {selectedBlogs?.length > 0 && (
          <button
            className={cn("button-square-stroke button-small", styles.head)}
            onClick={deleteBlogs}
          >
            <Icon name="trash" size="20" />
          </button>
        )}
      </div>

      <Table
        setVisible={setVisible}
        visible={visible}
        modalEditVisible={visibleEdit}
        setModalEditVisible={setVisibleEdit}
        setEdit={setEdit}
        edit={edit}
        getBlogsData={getBlogsData}
        videoList={videoList}
        setVideoList={setVideoList}
        selectedBlogs={selectedBlogs}
        setSelectedBlogs={setSelectedBlogs}
        handlePageChange={handlePageChange}
        totalPages={totalPages}
      />
    </div>
  );
}
