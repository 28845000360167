import React from "react";
import cn from "classnames";
import styles from "./Form.module.sass";
import Icon from "../Icon";

const SearchForm = ({
  className,
  onSubmit,
  placeholder,
  value,
  setValue,
  type,
  name,
  icon,
  style,
}) => {
  return (
    <div className={cn(className, styles.form)} >
      <input
        className={styles.input}
        type={type}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        name={name}
        placeholder={placeholder}
        style={style}
        required
      />
      <button className={styles.result}>
        <Icon name={icon} size="24" />
      </button>
    </div>
  );
};

export default SearchForm;
