import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import toast from "react-hot-toast";
import {
  uploadVideo,
  videoThumbnail,
} from "../../../../redux/slices/blog.slice";
import TextInput from "../../../../components/TextInput";
import File from "../../../../components/File";
import Loader from "../../../../components/Loader";
import { addMusic } from "../../../../redux/slices/music.slice";
import Dropdown from "../../../../components/Dropdown";

export default function Form({
  handleReelFileChange,
  videoLoading,
  featuredVideoLoading,
  uploadFeaturedVideo,
  stateChangeHandler,
  state,
  updateMusicVideo,
  isEdit,
  thumbnail,
  setthumbnail,
  handleReelImageChange,
  thumbnailLoading,
}) {
  const { influencers } = useSelector((state) => state.influencer);
  console.log("influencers", state);
  return (
    <div
      style={{
        backgroundColor: "#fff",
        padding: 20,
        borderRadius: 24,
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
    >
      <TextInput
        label="Title"
        className={""}
        name="text"
        type="text"
        placeholder="Title"
        required
        value={state?.title}
        onChange={(e) => {
          stateChangeHandler("title", e.target.value);
        }}
      />
      {!isEdit && (
        <Dropdown
          label={"Influencer (Optional)"}
          options={influencers || []}
          value={state.influencer || "Influencer Name"}
          setValue={(value) => {
            if (value == "") {
              stateChangeHandler("influencerId", "");
              stateChangeHandler("influencer", "");
            } else {
              stateChangeHandler("influencerId", value?.id);
              stateChangeHandler("influencer", value?.name);
            }
          }}
          
        />
      )}
      {!isEdit && (
        <File
          title={state?.thumbnailUrl ? "Thumbnail Uploaded" : "Upload Thumbnail"}
          videoLoading={thumbnailLoading}
          onChange={handleReelImageChange}
          label={"Thumbnail (Optional)"}
          accept={"image/*"}
        />
      )}
      {!isEdit && (
        <File
          title={
            state?.reelUrl
              ? "Video Uploaded"
              : state?.video?.name
              ? state?.video?.name?.slice(0, 6) + "..."
              : "Music"
          }
          videoLoading={videoLoading}
          onChange={handleReelFileChange}
          label={"Music Video"}
          accept={"video/*"}
        />
      )}

      <button
        onClick={isEdit ? updateMusicVideo : uploadFeaturedVideo}
        disabled={videoLoading || featuredVideoLoading}
        style={{
          backgroundColor: "#ff3c00",
          color: "#fff",
          border: "none",
          borderRadius: 4,
          // padding: 10,
          height: 40,
          cursor: videoLoading || featuredVideoLoading ? "no-drop" : "pointer",
          // alignSelf: "flex-start",
        }}
      >
        {featuredVideoLoading ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader height={25} width={25} />
          </div>
        ) : isEdit ? (
          " Edit Music"
        ) : (
          " Add Music"
        )}
      </button>
    </div>
  );
}
