import React, { useState } from "react";
import "./updateProfile.css"; // Import the CSS file
import TextInput from "../../components/TextInput";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../redux/slices/auth.slice";
import toast from "react-hot-toast";
import Loader from "../../components/Loader";

export default function UpdateProfile() {
  const { auth } = useSelector((state) => state.auth);
  const [username, setUsername] = useState(auth?.name || "");
  const [profileImage, setProfileImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState(auth?.profile || null);
  React.useEffect(() => {
    setUsername(auth?.name);
    setPreviewImage(auth?.profile);
  }, [auth]);
  const dispatch = useDispatch();
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImage(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!username) {
      toast.error("Name Required");
      return false;
    }
    if (!previewImage) {
      toast.error("Image Required");
      return false;
    } else {
      // Handle form submission logic
      console.log("Updated username:", username);
      console.log("Updated profile image:", profileImage);
      const formData = new FormData();
      formData.append("name", username);
      formData.append("file", profileImage);
      dispatch(
        updateProfile({
          payload: formData,
          id: auth?.id,
          callback: (data) => {
            console.log(data);
            if (data?.status) {
              toast.success("Profile Updated Successfully");
              setLoading(false);
            } else {
              toast.error("Failed to Update Profile");
              setLoading(false);
            }
          },
        })
      );
    }
  };

  return (
    <div className="container">
      <form onSubmit={handleSubmit} className="form">
        <div className="image-container">
          <label htmlFor="profileImage" className="image-label">
            <img
              src={previewImage || "https://via.placeholder.com/150"}
              alt="Profile Preview"
              className="image"
            />
          </label>
          <input
            type="file"
            id="profileImage"
            className="file-input"
            accept="image/*"
            onChange={handleImageChange}
          />
        </div>
        <div className="input-group">
          <TextInput
            label="Name"
            className={""}
            name="text"
            type="text"
            placeholder="Name"
            required
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
        </div>
        <button
          type="submit"
          className="submit-button"
          style={{
            backgroundColor: "#ff3c00",
            color: "#fff",
            border: "none",
            borderRadius: 4,
            padding: 10,
          }}
        >
          {loading ? <Loader height={20} width={20} /> : " Update Profile"}
        </button>
      </form>
    </div>
  );
}
