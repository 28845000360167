import React from "react";
import TextInput from "../../../../components/TextInput";
import File from "../../../../components/File";
import Editor from "../../../../components/Editor";
import Loader from "../../../../components/Loader";
import Dropdown from "../../../../components/Dropdown";
import moment from "moment";
import Checkbox from "../../../../components/Checkbox";

export default function Form({
  state,
  stateChangeHandler,
  handleFileChange,
  videoLoading,
  uploadVideo,
  videoUploadLoading,
  handleImageChange,
  imageLoading,
  options,
  editData,
  updateVideoTitle,
  handleReelImageChange,
  reelImageLoading,
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
    >
      {!editData && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 20,
          }}
        >
          {/* <div
            style={{
              marginTop: 4,
            }}
          >
            <Checkbox
              content={"Add Water Mark"}
              value={state.watermark}
              onChange={(e) => {
                stateChangeHandler("watermark", !state.watermark);
              }}
            />
          </div> */}
          <div
            style={{
              marginTop: 4,
            }}
          >
            <Checkbox
              content={"Breaking News"}
              value={state.isBreaking}
              onChange={(e) => {
                stateChangeHandler("isBreaking", !state.isBreaking);
              }}
            />
          </div>
        </div>
      )}
      <TextInput
        label="Title"
        className={""}
        name="text"
        type="text"
        placeholder="Title"
        required
        value={state?.title}
        onChange={(e) => {
          stateChangeHandler("title", e.target.value);
        }}
      />
      <TextInput
        label="Custom Date (Optional)"
        className={""}
        name="date"
        type="datetime-local"
        placeholder="Custom Date"
        required
        value={state?.selectedCustomDate}
        onChange={(e) => {
          const localDateTime = moment(e.target.value).format(
            "YYYY-MM-DDTHH:mm:ss.SSSZ"
          ); // Keep the local timezone offset
          stateChangeHandler("customDate", localDateTime);
          stateChangeHandler("selectedCustomDate", e.target.value);
        }}
      />
      {!editData && (
        <>
          <TextInput
            label="Schedule At (Optional)"
            className={""}
            name="date"
            type="datetime-local"
            placeholder="Schedule At"
            required
            value={state?.selectedSchedule}
            onChange={(e) => {
              const localDateTime = moment(e.target.value)
                .local()
                .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
              stateChangeHandler("scheduleAt", localDateTime);
              stateChangeHandler("selectedSchedule", e.target.value);
            }}
          />
          {/* <Dropdown
        label={"Select Blog"}
        options={options}
        value={state.blogName || "Blog Name"}
        setValue={(value) => {
          stateChangeHandler("blogId", value?.id);
          setTimeout(() => {
            stateChangeHandler("blogName", value?.title);
          }, 1000);
        }}
      /> */}
          <div
            style={{
              flex: 1,
            }}
          >
            <File
              title={
                state?.reelImage?.name
                  ? state?.reelImage?.name?.slice(0, 6) + "..."
                  : "Upload Image"
              }
              videoLoading={reelImageLoading}
              onChange={handleReelImageChange}
              label={"Reel Image (optional)"}
              accept={"image/*"}
            />
          </div>
          <div
            style={{
              flex: 1,
            }}
          >
            <File
              title={
                state?.thumbnailImage?.name
                  ? state?.thumbnailImage?.name?.slice(0, 6) + "..."
                  : "Upload Thumbnail"
              }
              videoLoading={imageLoading}
              onChange={handleImageChange}
              label={"Thumbnail (optional)"}
              accept={"image/*"}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 20,
              width: "100%",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                flex: 1,
              }}
            >
              <File
                title={
                  state?.reelUrl
                    ? "Video Uploaded"
                    : state?.video?.name
                    ? state?.video?.name?.slice(0, 6) + "..."
                    : "Upload Video"
                }
                accept={"video/*"}
                videoLoading={videoLoading}
                onChange={handleFileChange}
                label={"Reel Video (optional)"}
              />
            </div>
          </div>
        </>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 10,
          justifyContent: "start",
        }}
      >
        {state?.reelImage && (
          <button
            style={{
              backgroundColor: "#ff3c00",
              width: "fit-content",
              height: 50,
              borderRadius: 30,
              color: "white",
              display: "flex",
              alignItems: "center",
              marginTop: 20,
              justifyContent: "center",
              padding: "0px 20px",
              opacity:
                videoLoading ||
                videoUploadLoading ||
                reelImageLoading ||
                imageLoading
                  ? 0.5
                  : 1,
              cursor:
                videoLoading || reelImageLoading || videoUploadLoading
                  ? "not-allowed"
                  : "pointer",
            }}
            onClick={() => {
              stateChangeHandler("reelImageUrl", "");
              setTimeout(() => {
                stateChangeHandler("reelImage", null);
              }, 1000);
            }}
          >
            Remove Reel Image
          </button>
        )}
        {state?.reelUrl && (
          <button
            style={{
              backgroundColor: "#ff3c00",
              width: "fit-content",
              height: 50,
              borderRadius: 30,
              color: "white",
              display: "flex",
              alignItems: "center",
              marginTop: 20,
              justifyContent: "center",
              padding: "0px 20px",
              opacity:
                videoLoading ||
                videoUploadLoading ||
                reelImageLoading ||
                imageLoading
                  ? 0.5
                  : 1,
              cursor:
                videoLoading || reelImageLoading || videoUploadLoading
                  ? "not-allowed"
                  : "pointer",
            }}
            onClick={() => {
              stateChangeHandler("reelUrl", "");
              setTimeout(() => {
                stateChangeHandler("video", null);
              }, 300);
            }}
          >
            Remove Reel Video
          </button>
        )}
      </div>
      <button
        // className={cn("button", styles.button)}
        style={{
          backgroundColor: "#ff3c00",
          width: "100%",
          height: 50,
          borderRadius: 30,
          color: "white",
          display: "flex",
          alignItems: "center",
          marginTop: 20,
          justifyContent: "center",
          opacity:
            videoLoading ||
            videoUploadLoading ||
            reelImageLoading ||
            imageLoading
              ? 0.5
              : 1,
          cursor:
            videoLoading || reelImageLoading || videoUploadLoading
              ? "not-allowed"
              : "pointer",
        }}
        onClick={() => {
          if (
            videoLoading ||
            videoUploadLoading ||
            imageLoading ||
            reelImageLoading
          ) {
            return;
          } else {
            if (editData) {
              updateVideoTitle();
            } else {
              uploadVideo();
            }
          }
        }}
        disabled={
          videoLoading || videoUploadLoading || imageLoading || reelImageLoading
        }
      >
        {videoUploadLoading ? (
          <Loader white={"white"} />
        ) : editData ? (
          "Update Video"
        ) : (
          "Upload Video"
        )}
      </button>
    </div>
  );
}
