import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { endpoint } from "../../config/endpoint";
const initialState = {
  influencers: [],
  loading: false,
  page: 1,
  pageSize: 12,
  totalCount: 2,
  totalPages: 1,
};

export const getInfluencers = createAsyncThunk(
  "influencer/getInfluencers",
  async (data, thunkAPI) => {
    console.log("data", data);
    try {
      const response = await axios.get(
        `${endpoint}/v1/influencity?page=${data.payload.page}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const addInfluencer = createAsyncThunk(
  "influencer/addInfluencer",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${endpoint}/v1/influencity/add-influencer`,
        data.payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateInfluencer = createAsyncThunk(
  "influencer/updateInfluencer",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${endpoint}/v1/influencity/update-influencer`,
        data.payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const updateInfluencerStatus = createAsyncThunk(
  "influencer/updateInfluencerStatus",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${endpoint}/v1/influencity/update-influencer-status`,
        data.payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const deleteInfluencer = createAsyncThunk(
  "influencer/deleteInfluencer",
  async (data, thunkAPI) => {
    try {
      const response = await axios.delete(
        `${endpoint}/v1/influencity/delete-influencer/${data.payload?.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("j-token")}`,
          },
        }
      );
      data.callback && data.callback(response.data);
      return response.data;
    } catch (error) {
      data.callback && data.callback(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const influencitySlice = createSlice({
  name: "influencity",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getInfluencers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getInfluencers.fulfilled, (state, { payload }) => {
      console.log(payload);
      state.loading = false;
      state.influencers = payload?.influencers;
      state.page = payload?.page;
      state.pageSize = payload?.pageSize;
      state.totalPages = payload?.totalPages;
    });
    builder.addCase(getInfluencers.rejected, (state, payload) => {
      state.loading = false;
    });
  },
});

export const {} = influencitySlice.actions;
export default influencitySlice.reducer;
