import React, { useEffect, useState } from "react";
import styles from "./Table.module.scss";
import Tooltip from "../../../components/Tooltip";
import Modal from "../../../components/Modal";
import { useDispatch, useSelector } from "react-redux";
import { getAllVideos, verifyVideo } from "../../../redux/slices/video.slice";
import Icon from "../../../components/Icon";
import moment from "moment";
import TextInput from "../../../components/TextInput";
import Filters from "../../../components/Filters";
import Dropdown from "../../../components/Dropdown";
import Checkbox from "../../../components/Checkbox";
import toast from "react-hot-toast";
import Form from "../Form";
import {
  addBlog,
  deleteBlog,
  deleteBlogComment,
  getBlogs,
  updateBlog,
  uploadVideo,
} from "../../../redux/slices/blog.slice";
import Loader from "../../../components/Loader";
import {
  addCategory,
  getCategories,
  updateCategory,
} from "../../../redux/slices/category.slice";
import ReactPaginate from "react-paginate";

const Table = ({
  visible,
  setVisible,
  modalEditVisible,
  setModalEditVisible,
  setEdit,
  edit,
  getBlogsData,
  videoList,
  setVideoList,
  selectedBlogs,
  setSelectedBlogs,
  handlePageChange,
  totalPages
}) => {
  const dispatch = useDispatch();
  const { loading, blogs } = useSelector((state) => state.blogs);
  const { categories } = useSelector((state) => state.category);
  const [addLoading, setAddLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [blogComments, setblogComments] = useState([]);
  const [mainCheckbox, setMainCheckbox] = useState(false);
  console.log("selectedBlogs", selectedBlogs);
  const deleteComment = (id) => {
    dispatch(
      deleteBlogComment({
        payload: {
          commentId: id,
        },
        callback: (data) => {
          if (data?.status === 200) {
            getBlogsData();
            setIsCommentModalOpen(false);
            toast.success("Comment deleted successfully");
          }
        },
      })
    );
  };
  const addCategoryHandler = () => {
    try {
      if (!title) {
        toast.error("Title is required");
        return false;
      }

      setAddLoading(true);
      dispatch(
        addCategory({
          payload: {
            title: title,
          },
          callback: (data) => {
            setAddLoading(false);
            if (data?.status === 200) {
              getBlogsData();
              setTitle("");
              setVisible(false);
              toast.success(data?.message);
            } else {
              toast.error(data?.message);
            }
          },
        })
      );
    } catch (error) {
      setAddLoading(false);
      toast.error("Something went wrong");
    }
  };
  const updateCategoryHandler = (item, status) => {
    try {
      setAddLoading(true);
      dispatch(
        updateBlog({
          payload: {
            title: item?.title,
            status: status,
            id: item?.id,
          },
          callback: (data) => {
            setAddLoading(false);
            if (data?.status === 200) {
              getBlogsData();
              setVisible(false);
              toast.success(data?.message);
            } else {
              toast.error(data?.message);
            }
          },
        })
      );
    } catch (error) {
      setAddLoading(false);
      toast.error("Something went wrong");
    }
  };
  // update blog flow

  const handleUpdateBlogModalOpen = (data) => {
    setEdit(data);
    setModalEditVisible(true);
  };
  const handleDeleteBlog = (id) => {
    dispatch(
      deleteBlog({
        payload: {
          id,
          status: "delete",
        },
        callback: (data) => {
          if (data?.status === 200) {
            toast.success(data?.message);
            getBlogsData();
          } else {
            toast.error(data?.message || "Something went wrong");
          }
        },
      })
    );
  };
  const handleSeeComments = (comments) => {
    setblogComments(comments);
  };
  const selectedItemHandler = (x) => {
    setSelectedBlogs((prevBlogs) =>
      prevBlogs.includes(x?.id)
        ? prevBlogs.filter(
            (blogId) => blogId?.toLowerCase() !== x?.id?.toLowerCase()
          )
        : [...prevBlogs, x?.id]
    );
  };
  return (
    <div>
      <Modal
        visible={isCommentModalOpen}
        onClose={() => {
          setIsCommentModalOpen(false);
        }}
        title={"All Comments"}
      >
        <div className={styles.wrapper}>
          <div className={styles.table}>
            <div className={styles.rowHeading}>
              <div className={styles.col} data-label="Title">
                First Name
              </div>
              <div className={styles.col} data-label="Title">
                LastName
              </div>
              <div className={styles.col} data-label="Title">
                Email
              </div>
              <div className={styles.col} data-label="Title">
                Comment
              </div>
              <div className={styles.col} data-label="Status">
                Date
              </div>
              <div className={styles.col} data-label="Action">
                Action
              </div>
            </div>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100px",
                }}
              >
                <Loader />
              </div>
            ) : blogComments?.length > 0 ? (
              blogComments?.map((x, index) => (
                <div
                  className={styles.row}
                  style={{
                    backgroundColor: "#f4f4f4",
                  }}
                  key={index}
                >
                  <div className={styles.col} data-label="Title">
                    <p>{x?.firstName}</p>
                  </div>
                  <div className={styles.col} data-label="Title">
                    <p>{x?.lastName}</p>
                  </div>
                  <div className={styles.col} data-label="Status">
                    <p>{x?.email}</p>
                  </div>
                  <div
                    className={styles.col}
                    style={{
                      textAlign: "justify",
                    }}
                    data-label="Status"
                  >
                    <p>{x?.comment}</p>
                  </div>
                  <div className={styles.col} data-label="Title">
                    <p> {moment(x?.createdAt).format("MM/DD/YYYY")}</p>
                  </div>
                  <div className={styles.col} data-label="Action">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div onClick={() => deleteComment(x?.id)}>
                        <Icon
                          name={"trash"}
                          size="24"
                          className={styles.iconColorRed}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p
                style={{
                  fontSize: 20,
                  textAlign: "center",
                  marginTop: "100px",
                }}
              >
                No Data Found
              </p>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        visible={edit ? modalEditVisible : visible}
        onClose={
          !edit
            ? () => {
                setEdit(null);
                setVisible(false);
              }
            : () => {
                setEdit(null);
                setModalEditVisible(false);
              }
        }
        title={edit ? "Edit Blog" : "Add Blog"}
      >
        <div
          style={{
            margintop: 100,
          }}
        >
          <Form
            setTitle={setTitle}
            title={title}
            loading={addLoading}
            addCategory={addCategoryHandler}
            categories={categories}
            setVisible={edit ? setModalEditVisible : setVisible}
            getBlogsData={getBlogsData}
            videoList={videoList}
            editData={edit}
          />
        </div>
      </Modal>

      <div className={styles.wrapper}>
        <div className={styles.table}>
          <div className={styles.rowHeading}>
            <div className={styles.col} data-label="Title">
              {/* <Checkbox
                checked={selectedBlogs?.length > 0 ? true : false}
                onChange={(e) => {}}
              /> */}
            </div>
            <div className={styles.col} data-label="Title">
              Title
            </div>
            <div className={styles.col} data-label="Title">
              Category
            </div>
            <div className={styles.col} data-label="Title">
              Blog Status
            </div>
            <div className={styles.col} data-label="Title">
              Preview
            </div>
            <div className={styles.col} data-label="Title">
              Comments
            </div>
            <div className={styles.col} data-label="Status">
              Created At
            </div>
            <div className={styles.col} data-label="Action">
              Action
            </div>
          </div>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
              }}
            >
              <Loader />
            </div>
          ) : blogs?.length > 0 ? (
            blogs?.map((x, index) => (
              <div
                className={styles.row}
                style={{
                  backgroundColor: "#f4f4f4",
                }}
                key={index}
              >
                <div className={styles.col} data-label="checkbox">
                  <Checkbox
                    checked={selectedBlogs.includes(x?.id)}
                    onChange={(e) => selectedItemHandler(x)}
                  />
                </div>
                <div className={styles.col} data-label="Title">
                  <p>{x?.title}</p>
                </div>
                <div className={styles.col} data-label="Title">
                  <p>{x?.category}</p>
                </div>
                <div className={styles.col} data-label="Status">
                  <p>{x?.status}</p>
                </div>
                <div className={styles.col} data-label="Status">
                  <div
                    onClick={() => {
                      const link = `https://jewasity.com/news/${x?.redirectLink}`;
                      // const link = `https://dev-jewasity.vercel.app/blog/${x?.title}`;
                      // process.env.NODE_ENV == "development"
                      //   ? `http://localhost:3000/blog/${x?.title}`

                      window.open(link);
                    }}
                  >
                    <Icon
                      name={"link"}
                      size="24"
                      className={styles.iconColorGreen}
                    />
                  </div>
                </div>
                <div className={styles.col} data-label="Status">
                  <div
                    onClick={() => {
                      handleSeeComments(x?.comments);
                      setIsCommentModalOpen(true);
                    }}
                  >
                    <Icon
                      name={"message"}
                      size="24"
                      className={styles.iconColorGreen}
                    />
                  </div>
                </div>
                <div className={styles.col} data-label="Title">
                  <p> {moment(x?.createdAt).format("MM/DD/YYYY")}</p>
                </div>
                <div className={styles.col} data-label="Action">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    <div onClick={() => handleDeleteBlog(x?.id)}>
                      <Icon
                        name={"trash"}
                        size="24"
                        className={styles.iconColorRed}
                      />
                    </div>
                    <>
                      {x?.status === "ACTIVE" ? (
                        <div
                          onClick={() => updateCategoryHandler(x, "UNLISTED")}
                        >
                          <Icon
                            name={"close"}
                            size="24"
                            className={styles.iconColorRed}
                          />
                        </div>
                      ) : (
                        <div onClick={() => updateCategoryHandler(x, "ACTIVE")}>
                          <Icon
                            name={"check"}
                            size="24"
                            className={styles.iconColorGreen}
                          />
                        </div>
                      )}
                    </>
                    <div onClick={() => handleUpdateBlogModalOpen(x)}>
                      <Icon
                        name={"edit"}
                        size="24"
                        className={styles.iconColor}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p
              style={{
                fontSize: 20,
                textAlign: "center",
                marginTop: "100px",
              }}
            >
              No Data Found
            </p>
          )}
        </div>
      </div>
      <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        breakLabel={"..."}
        pageCount={totalPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={handlePageChange}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        activeClassName={"active"}  // Highlight active tab
      />
    </div>
  );
};

export default Table;
